import { styled } from 'utils/emotion';
import Icon from 'components/Icon';
import media from 'utils/mediaqueries';

export const FaqQuestionStyled = styled('div')({

});


export const QuestionStyled = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    position: 'relative',

    [media(0,'tablet')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },

    '&::after': {
        content: '" "',
        width: '100%',
        position: 'absolute',
        height: 1,
        background: theme.colorPrimary,
        opacity: 0.2,
        bottom: 0,
        left: 0,
    },
}));

export const NumberStyled = styled('div')({
    marginRight: 25,
    fontSize: 30,
    fontWeight: 900,
});


export const QuestionTextStyled = styled('div')({
    fontSize: 20,
    flexGrow: 1
});


export const AnswerStyled = styled('div')({
    fontSize: 15,
    padding: '10px 20px',
    
    [media(0,'tablet')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
});

export const IconStyled = styled(Icon)({
        marginLeft: 25,
        flexShrink: 0
    },
    props =>
        props.active && {
        transform: 'rotate(180deg)'
    }
);
