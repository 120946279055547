import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';

export const FaqsStyled = styled('div')({
    paddingBottom: 100,

    [media(0,'tablet')]: {
        paddingBottom: 50,
    },
});
