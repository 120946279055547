import React from "react";
import FaqQuestion from "components/FaqQuestion";

import { FaqsStyled } from "./Faqs.styles";

export default function Faqs({ items }) {
  return (
    <FaqsStyled>
      {items.map((item, index) => {
        if (!item) return null;

        return (
          <FaqQuestion
            key={"faq-" + index}
            item={item}
            number={String(index + 1).padStart(2, "0")}
          />
        );
      })}
    </FaqsStyled>
  );
}
