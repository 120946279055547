import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Text from 'components/Text';
import Headline from 'components/Headline';
import TextVisual from 'components/TextVisual';

export const ContentWrapperStyled = styled('div')({
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  zIndex: 2,
});

export const HeadlineStyled = styled(Headline)({
  zIndex: 2,
  marginBottom: 20
});

export const FaqWrapperStyled = styled('div')(({ theme }) => ({
  padding: '25px 0 20px',
  background: theme.sectionTextBackground,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  overflow: 'visible',
  maxWidth: '100vw',
  color: theme.colorPrimary,
  zIndex: 1,

  [media(0,'mobile')]: {
    // padding: '25px 0 20px',
  }  
}));

export const FaqContainerStyled = styled('div')(({ props }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
}));

export const TextStyled = styled(Text)({
  zIndex: 2,
});

export const TextWrapperStyled = styled('div')(({ props }) => ({
  zIndex: 2,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'left',
  padding: '25px 50px 25px 25px',

  [media(0,'mobile')]: {
    paddingRight: 25,
  }  
}));

export const TextVisualStyled = styled(TextVisual)({
  top: 220,
  width: 0,
  fontSize: 0,
  zIndex: 1,
  opacity: 0.2,

  [media('tabletFluid')]: {
    top: 0,
    left: 0,
    width: '1.2em',
    fontSize: '5vw',
  },

  [media('laptop')]: {
    fontSize: '5vw',
  },
});
