import React from 'react';
import { graphql } from 'gatsby';
import SwitchTheme from 'components/SwitchTheme';
import Redactor from 'components/Redactor';
import Grid from 'components/Grid';
import GridItem from 'components/GridItem';
import Faqs from 'components/Faqs';
import { parseAsText } from 'utils/parser';
import WrapGrid from 'components/WrapGrid';

import {
  ContentWrapperStyled,
  FaqContainerStyled,
  FaqWrapperStyled,
  TextStyled,
  HeadlineStyled,
  TextWrapperStyled,
  TextVisualStyled
} from './FaqStyled';

const Faq = ({ pageBuilder }) => {
  const {
    faqHeadline,
    faqHeadlineTag,
    faqTextvisual,
    faqTheme,
    faqText,
    faqQuestions
  } = pageBuilder;

  const textContent = faqText?.content;
  
  return faqQuestions ? (
    <SwitchTheme name={faqTheme}>
      <FaqWrapperStyled>
        <FaqContainerStyled>
          {(faqHeadline || textContent) && (
            <Grid>
              <GridItem mobile={1} tabletFluid={1}>
                <ContentWrapperStyled>
                  <TextWrapperStyled>
                    {faqTextvisual && (
                      <TextVisualStyled>{parseAsText(faqTextvisual)}</TextVisualStyled>
                    )}
                    {faqHeadline && (
                      <HeadlineStyled element={faqHeadlineTag}>
                        {faqHeadline}
                      </HeadlineStyled>
                    )}
                    {textContent && (
                        <TextStyled>
                          <Redactor content={textContent} parseType="content" />
                      </TextStyled>
                    )}
                  </TextWrapperStyled>
                </ContentWrapperStyled>
              </GridItem>
            </Grid>
          )}

          <WrapGrid gutter={56}>
            <Faqs items={faqQuestions} />
          </WrapGrid>
        </FaqContainerStyled>
      </FaqWrapperStyled>
    </SwitchTheme>
  ) : null;
};

export default Faq;

export const query = graphql`
  fragment PageBuilderFaqQuery on Craft_PageBuilderFaq {
    faqHeadline
    faqHeadlineTag
    faqTextvisual
    faqTheme
    faqText {
      content
    }
    faqQuestions {
      question
      answer {
        content
      }
    }
  }
`;
